import buttons from "./buttons";
import cards from "./cards";
import colors from "./colors";
import elements from "./elements";
import forms from "./forms";
import { Hero } from "./hero";
import layout from "./layouts";
import spacing from "./spacing";
import toc from "./toc";
import typeography, {
  headingStyles,
  textStyles,
  lineHeights,
} from "./typeography";
const theme = {
  breakpoints: ["768px", "1025px", "1290px"],

  ...spacing,
  ...typeography,
  ...lineHeights,
  colors: { ...colors },

  styles: {
    markup: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: 2,
      ...headingStyles,
      ...textStyles,
      li: {
        padding: "5px 10px 5px 25px",
        listStyle: "disc",
        margin: "20px",
        verticalAlign: "middle",
      },
      table: {
        borderCollapse: "collapse",
        margin: "25px 0",
        fontSize: "0.9em",
        width: "100%",
        minWidth: "400px",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",
      },
      td: { padding: "12px 15px;" },
      th: { padding: "12px 15px;" },
      thead: {
        tr: {
          bg: "secondary",
          color: "white",
          textAlign: "left",
        },
      },
      tbody: {
        tr: { borderBottom: "1px solid #dddddd" },
      },
    },
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: 2,
      ...headingStyles,

      li: {
        padding: "5px 10px 5px 25px",
        listStyle: "disc",
        margin: "20px",
        verticalAlign: "middle",
      },
      ".builder-text": {
        ...textStyles,
      },
      ".builder-block": {
        table: {
          borderCollapse: "collapse",
          margin: "25px 0",
          fontSize: "0.9em",
          width: "100%",
          minWidth: "400px",
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",
        },
        td: { padding: "12px 15px;" },
        th: { padding: "12px 15px;" },
        thead: {
          tr: {
            bg: "secondary",
            color: "white",
            textAlign: "left",
          },
        },
        tbody: {
          tr: { borderBottom: "1px solid #dddddd" },
        },
        li: {
          //padding: "5px 10px 5px 25px",
          //listStyle: "disc",
          //margin: "20px",
          verticalAlign: "middle",
        },
      },
      //...textStyles,
    },
    ...headingStyles,
    ...textStyles,
    ...elements,
  },

  ...layout,
  ...buttons,
  ...cards,
  ...toc,
  ...forms,
  hero: { ...Hero },
};

export default theme;
