const elements = {
  li: {
    background: "url('/check.svg') no-repeat left center",
    padding: "5px 10px 5px 25px",
    listStyle: "none",
    margin: 0,
    verticalAlign: "middle",
    ":before": {
      //paddingRight: 2,
      //content: "url('check.svg')"
    },
  },
};

export default elements;
