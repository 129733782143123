import { shadows } from "./colors";

const cardCommon = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  bg: "white",
  p: 4,
  height: "100%",
  boxShadow: shadows.grey,
  borderRadius: "4px",
  h3: { fontSize: [4], py: 2 },
  content: {
    li: {
      background: "url('/check.svg') no-repeat left center",
      //padding: "5px 10px 5px 25px",
      listStyle: "none",
      margin: 0,
      verticalAlign: "middle",
    },
    p: { color: "textMuted", fontSize: [1], pb: 2 },
  },
};

const cards = {
  cards: {
    primary: {
      ...cardCommon,
      height: "100%",
      alignItems:"center",
      textAlign:"center",
      colorPrimary: {
        ...cardCommon,
        boxShadow: shadows.primary,
      },
    },
    flat: {
      ...cardCommon,
      boxShadow: null,
      borderRadius: "0px",
      display: "flex",
      flexDirection: ["column"],
      alignItems: "flex-start",
      justifyContent: "space-between",
      bg: "white",

      //boxShadow:
      //   "0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)",
      // borderRadius: "4px",
      body: {
        display: "flex",
        flexDirection: "column",
        alignItems: ["center", "flex-start"],
        textAlign: ["center", "left"],
        width: ["100%", "50%", "50%"],
      },
    },
    icon: {
      display: "flex",
      //flexDirection: ["column", "row"],
      alignItems: "center",
      justifyContent: "space-between",
      bg: "white",
      pt: 5,
      //boxShadow:
      //   "0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)",
      // borderRadius: "4px",
      icon: { width: ["100%", "50%", "50%"], pb: [2, 2, 0] },
      body: {
        display: "flex",
        flexDirection: "column",
        alignItems: ["center", "flex-start"],
        textAlign: ["center", "left"],
        width: ["100%", "50%", "50%"],
      },
    },
    circle: {
      bg: "primaryLight",
      borderRadius: "50%",
      boxShadow: shadows.grey,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: [3],
      m: 2,
      color: "text",
    },
  },
};

export default cards;
