import pallet from "./color-pallets/site-colors";

const colors = {
  primaryLight: pallet.primary2[1],
  primary: pallet.primary2[4],
  primaryDark: pallet.primary2[8],
  primaryTransparent: `${pallet.primary2[4]}30`,

  whiteTransparent: pallet.whiteTransparent[9],

  secondaryLight: pallet.primary3[1],
  secondary: pallet.primary3[4],
  secondaryDark: pallet.primary3[8],
  secondaryTransparent: `${pallet.primary3[4]}80`,

  supportingALight: pallet.primary1[3],
  supportingA: pallet.primary1[4],
  supportingADark: pallet.primary1[8],
  supportingATransparent: `${pallet.primary1[4]}80`,

  text: pallet.primary1[4],
  textMuted: pallet.neutrals[0],
  textLight: pallet.neutrals[1],

  light: pallet.neutrals[1],

  background: pallet.white,
  backgroundFill: pallet.neutrals[2],
};

export default colors;

export const gradients = {
  primary: (): string => {
    return `linear-gradient(to right, ${pallet.primary2[2]} 10%, ${pallet.primary2[1]} 100%);`;
  },
};
export const shadows = {
  grey: " -1px 3px 10px 0 rgba(173,173,173,.34)",
  primary: " -1px 3px 43px 0 rgba(255, 102, 102,.34)",
  secondary: " -1px 3px 43px 0 rgba(41, 222, 199,.34)",
};

/*
const gradients= {
    primary: {
      backgroundImage: () => {
        return `linear-gradient(to right, ${pallet.primary[6]} 10%, ${pallet.primary[3]} 100%);`;
      },
    }
  };
*/
export const colorPallet = { ...pallet };
