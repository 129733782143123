import ColorPallet from "./color-pallet.interface";

const pallet: ColorPallet = {
  transparent: "transparent",
  black: "#000",
  white: "#ffffff",
  whiteTransparent: [
    "#FFFFFF10",
    "#FFFFFF20",
    "#FFFFFF30",
    "#FFFFFF40",
    "#FFFFFF50",
    "#FFFFFF60",
    "#FFFFFF70",
    "#FFFFFF80",
    "#FFFFFF90",
    "#FFFFFF99",
  ],
  //Grey
  primary1: [
    "#f4f8fa",
    "#b4d1df",
    "#70a8c2",
    "#407b96",
    "#244554",
    "#1f3a47",
    "#1a323d",
    "#14262e",
    "#0f1d24",
  ],
  //salmon
  primary2: [
    "#fff0f0",
    "#ffcccc",
    "#ffadad",
    "#ff8a8a",
    "#ff6666",
    "#ff1a1a",
    "#cc0000",
    "#800000",
    "#330000",
  ],
  //green
  primary3: [
    "#f2fdfb",
    "#c1f5ee",
    "#8cede0",
    "#5be6d3",
    "#2bdec6",
    "#1cb5a1",
    "#15897a",
    "#0e584e",
    "#072c27",
  ],

  //Blue
  secondary1: [],
  secondary2: [],
  secondary3: [],
  secondaryTransparent: [],
  //blue-gray
  neutrals: ["#7b7c7c", "#bcbcbb", "#dddddc"],

  supportingA: [
    "#dddddc",
    "#fb9f9f",
    "#7ce7d9",
    "#b99fee",
    "#9fe4f5",
    "#ffd300",
  ],

  supportingB: [
    "#7b7c7c",
    "#ff6666",
    "#2EBDB2",
    "#9175C9",
    "#75C5D9",
    "#CCAC14",
  ],
};

export default pallet;
