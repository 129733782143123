const buttons = {
  buttons: {
    primary: {
      color: "white",
      bg: "primary",
      py: 2,
      px: 3,
      fontSize: [3, 4, 4],
      borderRadius: "16px",
      textDecoration: "none",
      display: "block",
      cursor: "pointer",
      small: {
        color: "white",
        bg: "primary",
        display: "block",
        py: 2,
        px: 3,
        fontSize: [2],
        borderRadius: "8px",
        textDecoration: "none",
      },
      
      link: {
        color: "primary",
        fontSize: 1,
      },

      //boxShadow:"0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24)",
      outline: {
        color: "primary",

        py: 2,
        px: 3,
        fontSize: [3, 4, 4],
        borderRadius: "16px",
        borderColor: "primary",
        borderStyle: "solid",
        textDecoration: "none",
        borderWidth: 1,
        small: {
          color: "primary",
          py: 2,
          px: 3,
          fontSize: [2],
          borderRadius: "8px",
          borderColor: "primary",
          borderStyle: "solid",
          textDecoration: "none",
          borderWidth: 1,
          "&:hover": {
            bg: "primary",
            color: "white",
          },
        },
      },
    },
    secondary: {
      color: "white",
      bg: "secondary",
      py: 2,
      px: 3,
      fontSize: [3, 4, 4],
      borderRadius: "16px",
      textDecoration: "none",
      display: "block",
      cursor: "pointer",
      small: {
        color: "white",
        bg: "secondary",
        display: "block",
        py: 2,
        px: 3,
        fontSize: [2],
        borderRadius: "8px",
        textDecoration: "none",
      },

      link: {
        color: "secondary",
        fontSize: 1,
      },

      //boxShadow:"0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24)",
      outline: {
        color: "secondary",

        py: 2,
        px: 3,
        fontSize: [3, 4, 4],
        borderRadius: "16px",
        borderColor: "primary",
        borderStyle: "solid",
        textDecoration: "none",
        borderWidth: 1,
        small: {
          color: "primary",
          py: 2,
          px: 3,
          fontSize: [2],
          borderRadius: "8px",
          borderColor: "secondary",
          borderStyle: "solid",
          textDecoration: "none",
          borderWidth: 1,
          "&:hover": {
            bg: "secondary",
            color: "white",
          },
        },
      },
    },
    outline: {
      color: "primary",
      bg: "white",
      cursor: "pointer",
      py: 2,
      px: 3,
      fontSize: [3, 4, 4],
      borderRadius: "16px",
      borderColor: "primary",
      borderStyle: "solid",
      textDecoration: "none",
      borderWidth: 1,
      small: {
        color: "primary",
        bg: "white",
        cursor: "pointer",
        py: 2,
        px: 3,
        fontSize: [2],
        borderRadius: "8px",
        borderColor: "primary",
        borderStyle: "solid",
        textDecoration: "none",
        borderWidth: 1,
        "&:hover": {
          bg: "primary",
          color: "white",
        },
      },
    },
    primary_small: {
      color: "white",
      bg: "secondary",
      display: "block",
      py: 1,
      px: 2,
      fontSize: [1],
      borderRadius: "8px",
      textDecoration: "none",
    },
  },
};

export default buttons;
