const toc = {
  toc: {
    li: {
      color: "textMuted",
      padding: "0px 0px 0px 0px",
      listStyle: "disc",
      margin: "0px",
      ml: "20px",
      verticalAlign: "middle",
    },
  },
};

export default toc;
